import { ConsumerUpdateModel } from './Consumer';
import { HCPUpdateModel } from './HCP';

export interface QuestionsCategory {
  id: string;
  name: string;
  category_order: number;
  question_subcategories: CategoryQuestion[];
  checked: boolean;
  description_needed: boolean;
  answer_order: number | null;
  description: string | null;
}

export interface CategoryQuestion {
  id: string;
  name: string;
  question_order: number;
  answer_order: number | null;
  description: string | null;
  description_needed: boolean;
}

export interface LeadQuestion {
  question_subcategory?: string; //uuid
  question_category?: string;
  answer_order: number;
  description: string;
  question_text: string | undefined;
  description_needed: boolean;
}

export function parseRowQuestionsCategories(qc: QuestionsCategory[]): QuestionsCategory[] {
  return qc
    .sort((c1, c2) => c1.category_order - c2.category_order)
    .map((i) => {
      return {
        name: i.name,
        id: i.id,
        category_order: i.category_order,
        checked: false,
        answer_order: 0,
        description_needed: i.description_needed,
        question_subcategories: i.question_subcategories.map((q) => {
          return {
            id: q.id,
            name: q.name,
            question_order: q.question_order,
            answer_order: 0,
            description: '',
            description_needed: q.description_needed,
          } as CategoryQuestion;
        }),
      } as QuestionsCategory;
    });
}

export function convertQuestCatsToLeadQuests(qc: QuestionsCategory[]): LeadQuestion[] {
  const resArr = [] as LeadQuestion[];

  qc.forEach((category) => {
    // @ts-ignore
    if (category.answer_order > 0) {
      resArr.push({
        question_category: category.id,
        // @ts-ignore
        answer_order: category.answer_order,
        // @ts-ignore
        description: category.description,
        question_text: undefined,
        description_needed: category.description_needed,
      });
    }
    category.checked &&
      category.question_subcategories.map((q) => {
        // @ts-ignore
        if (q.answer_order > 0) {
          resArr.push({
            question_subcategory: q.id,
            // @ts-ignore
            answer_order: q.answer_order,
            // @ts-ignore
            description: q.description,
            question_text: undefined,
            description_needed: q.description_needed,
          });
        }
      });
  });

  return resArr;
}

export function mapLeadAnswersToQuestionsCategory(
  qCats: QuestionsCategory[],
  leadQuests: LeadQuestion[]
): [QuestionsCategory[], number] {
  let maxOrder = 0;

  leadQuests.forEach((lq) => {
    qCats.forEach((cat, i, arr) => {
      const cqIndex = cat.question_subcategories.findIndex((q) => q.id === lq.question_subcategory);
      if (lq.answer_order > maxOrder) {
        maxOrder = lq.answer_order;
      }
      if (cqIndex > -1) {
        arr[i].question_subcategories[cqIndex].answer_order = lq.answer_order;
        arr[i].question_subcategories[cqIndex].description = lq.description;
        arr[i].checked = true;
      }
      if (cat.id === lq.question_category) {
        arr[i].answer_order = lq.answer_order;
        arr[i].description = lq.description;
        arr[i].checked = true;
      }
    });
  });
  return [qCats, maxOrder];
}
export function mapQuestionsCategoryToQuestions(
  tmpSurvey: ConsumerUpdateModel | HCPUpdateModel,
  cats: QuestionsCategory[]
) {
  tmpSurvey.additional_questions.forEach((qc, index, arr) => {
    const questByCat = cats.find((cat) => cat.id === qc.question_category);

    const questBySubcat = cats.find((cat) =>
      cat.question_subcategories.some((que) => que.id === qc.question_subcategory)
    );

    if (questByCat) {
      arr[index].question_text = questByCat.name;
    }

    if (questBySubcat) {
      const question = questBySubcat.question_subcategories.find((q) => q.id === qc.question_subcategory);
      arr[index].question_text = `${questBySubcat.name}: ${question?.name}`;
    }
  });
  return tmpSurvey;
}
