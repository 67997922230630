import * as React from 'react';

function ErrorHandlerView() {
  return (
    <div style={{ margin: '40px auto', textAlign: 'center' }}>
      <h1>We&apos;re sorry. Something went wrong on this page</h1>
      <p>
        Contact support: <a href="mailto:support@rxdefine.com">support@rxdefine.com</a>
      </p>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorHandlerView />;
    }

    return this.props.children;
  }
}

export { ErrorBoundary, ErrorHandlerView };
export default ErrorBoundary;
