import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv } from 'mobx-easy';

import { RootEnv } from 'app/stores/config/CreateStore';
import { DefaultFilters, LeadFiltersModel } from 'app/models/LeadsModels';
import { ConsumersResult } from 'app/models/Consumer';

import { parsePagingResult } from '../../../models/CommonModels';

export class Consumers {
  leads: ConsumersResult;
  filters: LeadFiltersModel = DefaultFilters;

  busy = false;

  constructor() {
    makeAutoObservable(this);
  }

  async loadLeads(params?: string | undefined): Promise<boolean> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getLeads(params);

      runInAction(() => {
        this.leads = parsePagingResult(data);
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  setFilters(filters: LeadFiltersModel) {
    this.filters = filters;
  }

  setCustomerFiltersToLocalStorage(filters: number[]) {
    localStorage.setItem('selectedCustomers', JSON.stringify(filters));
  }

  initLocalStorageData() {
    const customersArray = localStorage?.selectedCustomers ? JSON.parse(localStorage.selectedCustomers) : [];

    this.filters = { ...this.filters, customers: customersArray };
  }

  resetLocalStorageData() {
    localStorage.removeItem('selectedCustomers');
  }

  clear() {
    this.filters = {};
  }
}
