import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';

import { Customer } from 'app/models/Lead';
import { RootEnv } from 'app/stores/config/CreateStore';
import RootStore from 'app/stores/RootStore';

export class LeadCustomers {
  customers: Customer[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async loadCustomers(): Promise<boolean> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getCustomersList();

      runInAction(() => {
        this.customers = data.results;
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async loadActiveCustomers(): Promise<Customer[]> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getCustomersList(true);

      return data.results;
    } catch (error) {
      const {
        uiStores: { notifierStore },
      } = getRoot<RootStore>();

      notifierStore.notify('Customers has not been loaded. Pelease try again later');

      return [];
    }
  }

  reset() {
    this.customers = [];
  }
}
