/* eslint-disable @typescript-eslint/no-explicit-any */
import { Conversation, Message } from '@twilio/conversations';
import { makeAutoObservable, observable, when } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import * as Sentry from '@sentry/browser';

import { IDataResult, IResponseResult, PaginatorTwilioMesssages } from 'app/models/CommonModels';
import { PreviewUploadModel } from 'app/models/FileModel';
import { RootEnv } from 'app/stores/config/CreateStore';
import RootStore from 'app/stores/RootStore';
import { sortChatMessagesByDate } from 'app/utils/SortingHeplers';
import { insertObjectsBetweenDates } from 'app/utils/ArrayHelpers';

import { JoiningIdentityMsg, OptInIdentityMsg } from '../../../models/ChatModels';

type LoadingState = 'initializing' | 'ready' | 'failed';
export interface IChat {
  conversationProxy: any;
  messages: any[];
  loadingState: string;
  boundConversations: Set<any>;
  twillioMessagesCount: number;
}

export class SmsChat {
  chat: IChat = {
    conversationProxy: null,
    messages: [],
    loadingState: 'initializing',
    boundConversations: new Set<[]>(),
    twillioMessagesCount: 0,
  };

  conversation?: Conversation;
  newMessage = '';
  oldMessages?: Message[] = [];
  joinHistory = observable.array();
  joinHistoryReady = false;
  loadingState: LoadingState = 'initializing';
  newMessages: Message[] = [];
  // @ts-ignore
  paginator: PaginatorTwilioMesssages<any> = null;
  chat_is_focused = false;
  local_unread_message_counter = 0;
  newPastedMessage = '';

  get isReady() {
    return this.loadingState === 'ready';
  }

  get initialMessages() {
    const {
      dataStores: {
        chatsStore,
        leadStore: { consumerStore },
      },
    } = getRoot<RootStore>();

    const messages = this.oldMessages || [];
    const joiningMessages = this.joinHistory || [];

    const msgs = [...messages, ...joiningMessages].sort(sortChatMessagesByDate);
    const identity = msgs[msgs.length - 1]?.identityName ?? '';

    if (msgs.length && identity !== chatsStore.conciergeIdentity) {
      msgs.push({
        identityName: chatsStore.conciergeIdentity,
        joiningDate: new Date(),
      } as JoiningIdentityMsg);
    }

    if (msgs.length) {
      msgs.push({
        isOptedIn: consumerStore.isOptedInForChats,
        identityName: consumerStore.shortLeadInfo?.patient_name,
      } as OptInIdentityMsg);
    }

    return msgs;
  }

  get messages() {
    return insertObjectsBetweenDates(this.initialMessages.concat(this.newMessages));
  }

  constructor() {
    makeAutoObservable(this, {
      chat: observable.ref,
      conversation: observable.ref,
    });
  }

  async setConversation(conversation?: Conversation) {
    // @ts-ignore
    if (this.conversation?.sid === conversation.sid) {
      return;
    }

    this.conversation = conversation;
    // @ts-ignore
    this.conversation.on('messageAdded', this.handleMessageAdded);

    try {
      // @ts-ignore
      this.setPaginator(await this.conversation.getMessages());

      this.setMessages(this.paginator.items);
      await this.conversation?.setAllMessagesRead();
      this.setLoadingState('ready');
    } catch (error) {
      Sentry.captureException(error);
      this.setLoadingState('failed');
    }
  }

  setJoinHistory(history = []) {
    this.joinHistoryReady = true;
    this.joinHistory.replace(history);
  }

  setLoadingState(state: LoadingState): void {
    this.loadingState = state;
  }

  setNewMessage(message: string) {
    this.newMessage = message;
  }
  setNewPastedMessage(message: string) {
    this.newPastedMessage = message;
  }

  handleMessageAdded = (message: Message) => {
    this.newMessages = [...this.newMessages, message];
    !this.chat_is_focused && this.incrementLocalUnreadMessagesCounter();
  };

  setMessages(messages: Message[]) {
    when(() => this.joinHistoryReady).then(() => {
      // @ts-ignore
      this.oldMessages = [...messages, ...this.oldMessages];
    });
  }

  setChat(newChat: IChat) {
    this.chat = newChat;
  }

  setPaginator(newPaginator: PaginatorTwilioMesssages<any>) {
    this.paginator = newPaginator;
  }

  incrementMsgsCount() {
    this.chat.twillioMessagesCount++;
  }

  setChatFocus(focused: boolean) {
    this.chat_is_focused = focused;
    focused && this.resetLocalUnreadMessagesCounter();
  }

  incrementLocalUnreadMessagesCounter = () => {
    this.local_unread_message_counter++;
  };

  resetLocalUnreadMessagesCounter() {
    this.local_unread_message_counter = 0;
  }

  joinConsierge() {
    const {
      dataStores: { chatsStore },
    } = getRoot<RootStore>();

    this.setChat({
      ...this.chat,
      messages: [
        ...this.chat.messages,
        {
          identityName: chatsStore.conciergeIdentity,
          joiningDate: new Date(),
        } as JoiningIdentityMsg,
      ],
      loadingState: 'ready',
    });
  }

  async attachFileToChat(
    conversation_uuid: string,
    bodyFormData: FormData,
    onUploadProgress?: (event: ProgressEvent) => void
  ): Promise<IDataResult<PreviewUploadModel>> {
    try {
      const { fileService, chatService } = getEnv<RootEnv>();

      const { data: uploadResult } = await fileService.uploadFile(bodyFormData, onUploadProgress);

      const { data: material } = await chatService.attachMaterialToConversation(conversation_uuid, uploadResult.uuid);

      return { success: true, data: material };
    } catch (error) {
      // @ts-ignore
      return { success: false, data: null };
    }
  }

  async detachFileFromChat(file_uuid: string): Promise<IResponseResult> {
    try {
      const { chatService } = getEnv<RootEnv>();

      await chatService.detachMaterialFromConversation(file_uuid);

      return { success: true };
    } catch (error) {
      return { success: false };
    }
  }

  dispose() {
    this.conversation?.off('messageAdded', this.handleMessageAdded);
    this.conversation = undefined;
    this.newMessage = '';
    this.oldMessages = [];
    this.newMessages = [];
    this.joinHistory.clear();
    this.joinHistoryReady = false;
    this.loadingState = 'initializing';
  }
}
