import { CssBaseline, Hidden, Paper as MuiPaper, useMediaQuery, useTheme, withWidth } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { observer } from 'mobx-react-lite';
import React, { useState, useRef, useEffect, type PropsWithChildren } from 'react';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { useSound } from 'use-sound';

import routes from 'app/routes';
import { ConversationActionType, WebConversation, WSMsg } from 'app/models/ChatModels';
import Notifier from 'app/components/modules/common/Notifier';
import { Sleep } from 'app/utils/Sleep';
import analytics from 'app/services/analytics';

import { RouteItem } from '../../models/RouteModels';
import { useStores } from '../../stores/config/UseStores';
import ConfirmationDialog from '../modules/layout/ConfirmationDialog';
import Footer from '../modules/layout/Footer';
import Header from '../modules/layout/Header';
import Sidebar from '../modules/layout/Sidebar';

const drawerWidth = 250;

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .scrollbar-container {
    position: relative;
    height: 100%;
  }

  .ps {
    overflow: hidden;
    touch-action: auto;
  }

  .ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    height: 15px;
    bottom: 0px;
    position: absolute;
  }

  .ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
    width: 15px;
    right: 0;
    position: absolute;
  }

  .ps--active-x > .ps__rail-x,
  .ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
  }

  .ps:hover > .ps__rail-x,
  .ps:hover > .ps__rail-y,
  .ps--focus > .ps__rail-x,
  .ps--focus > .ps__rail-y,
  .ps--scrolling-x > .ps__rail-x,
  .ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
  }

  .ps__thumb-x {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
    height: 6px;
    bottom: 2px;
    position: absolute;
  }

  .ps__thumb-y {
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
    width: 6px;
    right: 2px;
    position: absolute;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DashboardLayoutProps = PropsWithChildren<RouteComponentProps<any>>;

const DashboardLayout = ({ children, history, location }: DashboardLayoutProps) => {
  const {
    dataStores: { authStore, chatsStore, userStore },
    uiStores: { notifierStore, dialogStore },
  } = useStores();
  analytics.initialize();
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(isMobileDevice);
  const [routesState, setRoutesState] = useState<RouteItem[]>(routes);
  const [busy, setBusy] = useState(false);
  const [notifyWeb] = useSound(userStore.webNotificationSound, {
    preload: true,
  });
  const [notifySMS] = useSound(userStore.smsNotificationSound, {
    preload: true,
  });

  const soundWeb = useRef(null);
  const soundSms = useRef(null);

  // @ts-ignore
  soundWeb.current = notifyWeb;
  // @ts-ignore
  soundSms.current = notifySMS;

  const smsChatsStore = chatsStore.smsChatsStore;
  const webChatsStore = chatsStore.webChatsStore;

  useEffect(() => {
    if (authStore.authData.access) {
      //init chats and notifications

      (async () => {
        await Sleep(authStore.expTimeInMs - 5000).then(() => authStore.refresh());
      })();

      chatsStore.openCommunicationsChannel(authStore.authData.access, true);

      return () => {
        smsChatsStore.closeWSNotifications();
        webChatsStore.closeWSNotifications();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.authData.access]);

  useEffect(() => {
    if (smsChatsStore.wsNotificationMessage) {
      const message: WSMsg = smsChatsStore.wsNotificationMessage;

      if (
        message.action === ConversationActionType.NewMessage &&
        smsChatsStore.activeConversationSid !== message.conversation_sid &&
        message.source === 'survey_conversation'
      ) {
        // @ts-ignore
        userStore.userProfileData.sms_chat_sound_enable && soundSms.current();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsChatsStore.wsNotificationMessage]);

  useEffect(() => {
    if (webChatsStore.wsNotificationMessage) {
      const message: WSMsg = webChatsStore.wsNotificationMessage;
      if (
        message.action === ConversationActionType.NewMessage &&
        webChatsStore.chats.activeConversationSid !== message.conversation_sid &&
        message.source === 'web_conversation'
      ) {
        analytics.captureChatNotification(message.data as WebConversation);
        // @ts-ignore
        userStore.userProfileData.web_chat_sound_enable && soundWeb.current();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webChatsStore.wsNotificationMessage]);

  useEffect(() => {
    const chatRoute = routesState.find((r) => r.path === '/web-chats')!;
    chatRoute.counter = webChatsStore?.unreadMsgCount?.all;
    setRoutesState([...routesState]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webChatsStore?.unreadMsgCount]);

  useEffect(() => {
    const chatRoute = routesState.find((r) => r.path === '/chats')!;
    chatRoute.counter = smsChatsStore?.unreadMsgCount;
    setRoutesState([...routesState]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsChatsStore?.unreadMsgCount]);

  useEffect(() => {
    (async () => await handleLocationChange())();

    if (location.pathname === '/chats') {
      const chatRoute = routesState.find((r) => r.path === '/chats')!;
      if (chatRoute.id !== 'SMS Texts' && !smsChatsStore.hasUnread) {
        chatRoute.id = 'SMS Texts';
        setRoutesState([...routesState]);
      }
    }

    if (location.pathname === '/web-chats') {
      const chatRoute = routesState.find((r) => r.path === '/web-chats')!;
      if (chatRoute.id !== 'Web Chats' && !webChatsStore.hasUnread) {
        chatRoute.id = 'Web Chats';
        setRoutesState([...routesState]);
      }
    }

    const sid = new URLSearchParams(location.search).get('sid');
    !sid && smsChatsStore.unblockChat();
    webChatsStore.unblockChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (authStore.isAuth && authStore.isConcierge) {
      (async () => {
        await userStore.getConciergeProfileData();
        await userStore.loadSoundNotifications();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore?.authData?.access]);

  const handleLocationChange = async () => {
    setBusy(true);
    if (!authStore.isAuth) {
      await authStore.refresh();
    }
    setBusy(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        {/* @ts-ignore */}
        <Hidden
          mdUp
          implementation="js"
        >
          <Sidebar
            routes={routesState}
            PaperProps={{ style: { width: drawerWidth } }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        {/* @ts-ignore */}
        <Hidden
          smDown
          implementation="css"
        >
          <Sidebar
            routes={routesState}
            PaperProps={{ style: { width: drawerWidth } }}
          />
        </Hidden>
      </Drawer>
      <AppContent>
        <Header
          onDrawerToggle={handleDrawerToggle}
          onLogout={() => authStore.logout()}
          onProfile={() => history.push('profile')}
        />
        <MainContent>{busy ? 'Loading...' : children}</MainContent>
        <Footer />
      </AppContent>
      <Notifier
        {...notifierStore.notifier}
        onClose={notifierStore.resetNotifier}
      />
      <ConfirmationDialog
        {...dialogStore.dialog}
        onClose={dialogStore.dialog.onClose}
      />
    </Root>
  );
};

export default withWidth()(withRouter(observer(DashboardLayout))) as unknown as React.ComponentClass;
