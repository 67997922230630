import React, { type ComponentType, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { BrowserRouter as Router, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import AuthLayout from 'app/components/layouts/Auth';
import { type RouteItem } from 'app/models/RouteModels';
import { useStores } from 'app/stores/config/UseStores';

import { authRoutes, dashboardRoutes } from './index';
import DashboardLayout from '../components/layouts/Dashboard';

const SentryRoute = Sentry.withSentryRouting(Route);

type Props = {
  children: React.ReactNode;
};

const CheckAuth = withRouter(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  observer(({ history, children }: RouteComponentProps<any> & Props) => {
    const [busy, setBusy] = useState(false);

    let disposeHistoryOnChange;
    const {
      dataStores: { authStore },
    } = useStores();

    const handleLocationChange = async (location) => {
      setBusy(true);
      if (!authStore.isAuth) {
        await authStore.refresh();
      } else {
        authStore.sync();

        (location.pathname === '/' || location.pathname === '/auth') && history.replace('leads');
      }
      setBusy(false);
    };

    useEffect(() => {
      handleLocationChange(history.location);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      disposeHistoryOnChange = history.listen(handleLocationChange);

      return disposeHistoryOnChange();
    }, [false]);

    return busy ? <>Loading...</> : <>{children}</>;
  })
);

const ChildRoutes = ({ layout: Layout, routes }: { layout: React.ComponentClass; routes: RouteItem[] }) => {
  const SuspenseFallback = <>Loading...</>;

  return (
    <Layout>
      <Switch>
        {routes.map((category, index) => {
          const CategoryComponent = category.component as ComponentType;

          return category.children ? (
            // Route item with children
            category.children.map((route, index) => {
              const RouteComponent = route.component as ComponentType;

              return (
                <SentryRoute
                  key={index}
                  path={route.path}
                  exact
                  render={() => {
                    return (
                      <React.Suspense fallback={SuspenseFallback}>
                        <RouteComponent />
                      </React.Suspense>
                    );
                  }}
                />
              );
            })
          ) : (
            // Route item without children
            <SentryRoute
              key={index}
              path={category.path}
              exact
              render={() => {
                return (
                  <React.Suspense fallback={SuspenseFallback}>
                    <CategoryComponent />
                  </React.Suspense>
                );
              }}
            />
          );
        })}
      </Switch>
    </Layout>
  );
};

const Routes = () => {
  return (
    <Router>
      <CheckAuth>
        <Switch>
          {/* Auth routes */}
          <SentryRoute
            path="/auth*"
            exact
            component={() => (
              <ChildRoutes
                layout={AuthLayout}
                routes={authRoutes}
              />
            )}
          />

          {/* Dashboard routes */}
          <SentryRoute
            path="/*"
            exact
            component={() => (
              <ChildRoutes
                layout={DashboardLayout}
                routes={dashboardRoutes}
              />
            )}
          />
        </Switch>
      </CheckAuth>
    </Router>
  );
};

export default Routes;
