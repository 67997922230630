import { getRoot } from 'mobx-easy';

import { ConversationActionType, WebConversation, WSMsg } from 'app/models/ChatModels';
import posthog from 'app/services/posthog';
import RootStore from 'app/stores/RootStore';
import { Material, MaterialsGroup } from 'app/models/CustomerModels';
import { BaseConciergeModel } from 'app/models/UserModels';

enum Events {
  WEB_CHAT_NOTIFICATION_PLAYED = 'crm.webChat.notificationPlayed',
  WEB_CHAT_START = 'crm.webChat.startChat',
  WEB_CHAT_START_FAILED = 'crm.webChat.startChatFailed',
  WEB_CHAT_OPENED = 'crm.webChat.opened',
  WEB_CHAT_ARCHIVED = 'crm.webChat.archived',
  WS_MESSAGE_RECEIVED = 'crm.sockets.notificationReceived',
  QUICK_LINK_MATERIAL_OPENED = 'crm.sidebar.quickLinkOpened',
  QUICK_LINK_GROUP_OPENED = 'crm.sidebar.quickGroupOpened',
  FILTER_CUSTOMER_SELECTED = 'crm.chatFilter.customersSelected',
  FILTER_CONCIERGE_SELECTED = 'crm.chatFilter.conciergeSelected',
  FILTER_DATE_SELECTED = 'crm.chatFilter.dateSelected',
  FILTER_TEXT_SEARCH = 'crm.chatFilter.searchText',
}

interface IWebChatEvent {
  chatUuid: string;
  friendlyName: string;
  chatStage: number;
}

export interface IWebChatStartEvent extends IWebChatEvent {
  lastMessageDt: string;
  unreadUserMessagesCount: number;
}

type IMinChatData = Pick<WebConversation, 'uuid' | 'friendly_name' | 'stage'>;

let analyticsInitialized = false;
let tabSessionId: string | null = null;

const captureChatEvent = (event: Events, chat: IMinChatData) => {
  captureRawEvent(event, {
    chatUuid: chat.uuid,
    friendlyName: chat.friendly_name,
    chatStage: chat.stage,
  } as IWebChatEvent);
};

const captureChatStart = (
  chat: Pick<WebConversation, 'uuid' | 'friendly_name' | 'stage' | 'last_message_dt' | 'unread_user_messages_count'>
) => {
  captureRawEvent(Events.WEB_CHAT_START, {
    chatUuid: chat.uuid,
    friendlyName: chat.friendly_name,
    chatStage: chat.stage,
    lastMessageDt: chat.last_message_dt,
    unreadUserMessagesCount: chat.unread_user_messages_count,
  } as IWebChatStartEvent);
};

const captureChatStartFailed = (chat: IMinChatData) => {
  captureChatEvent(Events.WEB_CHAT_START_FAILED, chat);
};

const captureChatOpened = (chat: IMinChatData) => {
  captureChatEvent(Events.WEB_CHAT_OPENED, chat);
};

const captureChatNotification = (chat: IMinChatData) => {
  captureChatEvent(Events.WEB_CHAT_NOTIFICATION_PLAYED, chat);
};

const captureChatArchived = (chat: IMinChatData) => {
  captureChatEvent(Events.WEB_CHAT_ARCHIVED, chat);
};

const captureQuickLinkClicked = (material: Material) => {
  captureRawEvent(Events.QUICK_LINK_MATERIAL_OPENED, {
    materialName: material.name,
  });
};

const captureQuickLinkGroupClicked = (group: MaterialsGroup) => {
  captureRawEvent(Events.QUICK_LINK_GROUP_OPENED, {
    materialName: group.group_name,
  });
};

const captureWsActionMsg = (wsMsg: WSMsg) => {
  const ignored = [
    ConversationActionType.ActiveChatScreen, // too noisy to capture
    // ConversationActionType.UserOffline, // too frequent/noisy to capture
    // ConversationActionType.UserOnline, // too frequent/noisy to capture
    // ConversationActionType.Block, // not watched/profiled
  ];
  if (wsMsg.source != 'web_conversation' || ignored.includes(wsMsg.action)) {
    return;
  }

  captureRawEvent(Events.WS_MESSAGE_RECEIVED, {
    chatUuid: wsMsg.chat_uuid,
    action: wsMsg.action,
    notificationType: wsMsg.notification_type,
    uuid: 'uuid' in wsMsg.data ? wsMsg.data.uuid : 'absent',
    stage: 'stage' in wsMsg.data ? wsMsg.data.stage : 'absent',
    friendlyName: 'friendly_name' in wsMsg.data ? wsMsg.data.friendly_name : 'absent',
  });
};

export interface InFilterCustomer {
  value: {
    id: number;
    uuid: string;
    name: string;
  };
  label: string;
}

const captureCustomersFilterSelected = (newSelectedCustomers: InFilterCustomer[]) => {
  captureRawEvent(Events.FILTER_CUSTOMER_SELECTED, {
    activeCustomers: newSelectedCustomers.map((item) => {
      return { name: item.value.name, uuid: item.value.uuid };
    }),
  });
};

const captureConciergeFilterSelected = (newConcierge: BaseConciergeModel) => {
  captureRawEvent(Events.FILTER_CONCIERGE_SELECTED, {
    selected: newConcierge.twilio_id,
  });
};

const captureDateFilterSelected = (date: Date, name: string) => {
  captureRawEvent(Events.FILTER_DATE_SELECTED, {
    name,
    dateValue: date.getDate() ? date.toISOString() : 'not defined',
  });
};

const captureChatNameSearched = (searchedText) => {
  captureRawEvent(Events.FILTER_TEXT_SEARCH, {
    searchedText: searchedText,
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const captureRawEvent = (eventId: string, eventProps: any) => {
  if (analyticsInitialized) {
    // eslint-disable-next-line no-console
    console.info('CRM-analytics-event', eventId, eventProps);
    posthog.captureEvent(eventId, { tabId: tabSessionId, ...eventProps });
  } else {
    // eslint-disable-next-line no-console
    console.error('Analytics is not initialized');
  }
};

const initialize = () => {
  if (analyticsInitialized) {
    return;
  }
  const {
    dataStores: { userStore, authStore },
  } = getRoot<RootStore>();
  tabSessionId = Math.random().toString(36).slice(2);
  posthog.initialize();
  posthog.identify(userStore.userProfileData.email, {
    id: authStore.userId.toString(),
    role: authStore.profileType,
    uuid: userStore.userUUID.toString(),
    name: userStore.userProfileData.first_name,
  });
  analyticsInitialized = true;
};

const reset = () => {
  analyticsInitialized = false;
  tabSessionId = null;
  posthog.reset();
};

export default {
  // base methods
  initialize,
  // captureRawEvent,
  reset,

  // helper methods and shortcuts
  captureChatNotification,
  captureChatStart,
  captureChatStartFailed,
  captureChatOpened,
  captureChatArchived,
  captureWsActionMsg,
  captureQuickLinkClicked,
  captureQuickLinkGroupClicked,
  captureCustomersFilterSelected,
  captureConciergeFilterSelected,
  captureDateFilterSelected,
  captureChatNameSearched,
};
