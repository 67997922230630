const ENVIRONMENT = (
  window as typeof window & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ENVIRONMENT: { [key: string]: any };
  }
).ENVIRONMENT;

export const config = {
  API_URL: ENVIRONMENT?.API_URL,
  WEBSOCKET_URL:
    ENVIRONMENT?.WEBSOCKET_URL ||
    ENVIRONMENT?.API_URL.replace('https', 'wss').replace('http', 'ws').replace('/api/v1/', '') +
      '/ws/v2/notifications/',
  SHOULD_IGNORE_API_PREFIX: ENVIRONMENT?.SHOULD_IGNORE_API_PREFIX,
  SENTRY_DSN: ENVIRONMENT?.SENTRY_DSN,
  ENVIRONMENT_NAME: ENVIRONMENT?.ENVIRONMENT_NAME,
  AW_CONVERSION_ID: ENVIRONMENT?.AW_CONVERSION_ID,
  POSTHOG_KEY: ENVIRONMENT?.POSTHOG_KEY,
  POSTHOG_API_HOST: ENVIRONMENT?.POSTHOG_API_HOST,
  LAUNCHDARKLY_CLIENT_SIDE_ID: ENVIRONMENT?.LAUNCHDARKLY_CLIENT_SIDE_ID,
  GOOGLE_AUTH_CLIENT_ID: ENVIRONMENT?.GOOGLE_AUTH_CLIENT_ID,
};
export default config;
