import {
  InsuranceType,
  LeadCreationType,
  LeadFlowType,
  LeadStage,
  PageItemType,
  PatientQuestionType,
  PostLeadStatus,
  SurveyAnswer,
  SurveyQuestionType,
} from '.';
import { JoiningIdentityMsg, StopMessageDetails } from '../ChatModels';
import { ErrorType, Gender, ISimpleObj, USTimezonesValues } from '../CommonModels';
import { ConsumerStatus, ConsumerUpdateModel } from '../Consumer';
import { HCPErrorModel, HCPUpdateModel } from '../HCP';
import { LeadQuestion } from '../LeadQuestionsModels';
import { ConsumerType } from '../LeadsModels';

export class BaseLeadModel {
  id: number;
  uuid: string;
  post_status: PostLeadStatus;
  stage: LeadStage;
  concierge: Concierge;
  customer: Customer;
  product: Product;
  created_dt: string;
  assigned_dt: string;
  on_hold_dt: string;
  completed_dt: string;
  flow_type: LeadFlowType;
  last_contacted_dt: string;
  detected_geolocation: GeoLocation;
  flow_builder: boolean;
  created_from: LeadCreationType;
  friendly_name: string;
  opt_in: boolean;
  stop_message_detail: StopMessageDetails;
  test_only: boolean;
}

export class LeadModel extends BaseLeadModel {
  // phone_call_duration: string;
  additional_questions: LeadQuestion[];
  questions: SurveyQuestion[];
  followups: {
    passing_dt: string;
    questions: SurveyQuestion[];
  }[];
  followup_url: string;
  followup_notifications_history: string[];
  complete_reason: string;
  notes: string;
  protocol_name: string;
  dialpad_url: string;
  communication_channel: string | null;
  conditon_documents: string[] | null;
  test_only: boolean;
}

export class LeadUpdateModel {
  uuid: string;
  shortUuid: string;
  additional_questions: LeadQuestion[];
  complete_reason: string;
  notes: string;
  assigned_dt: Date;
  stage: LeadStage;
  customer: Customer;
  product: Product;
  protocol_name: string;
  detected_geolocation: GeoLocation;
  last_contacted_dt: string;
  dialpad_url: string;
  caregiver: CargiverUpdate | null;
  communication_channel: string | null;
  flow_builder: boolean;
  created_from: LeadCreationType;
  friendly_name: string;
  conditon_documents: string[];
  opt_in: boolean;
  stop_message_detail: StopMessageDetails;
  test_only: boolean;
}

export class LeadRequestModel {
  additional_questions: LeadQuestion[];
  complete_reason: string;
  notes: string;
  stage: LeadStage;
  flow_builder: boolean;
  conditon_documents: string[];
  opt_in: boolean;
  stop_message_detail: StopMessageDetails;
  test_only: boolean;
}

export interface Caregiver {
  uuid: string;
  email: string;
  phone: string;
  gender: Gender;
  age: number;
}
export interface CargiverUpdate extends Caregiver {
  zip_code: string;
}
export interface CaregiverRequest extends Caregiver {
  zip_code: ZipCode;
}
export interface BaseShortLeadModel {
  blocked: boolean;
  survey_uuid: string;
  mms_enabled: boolean;
  conversation_sid: string;
  survey_status: ConsumerStatus;
  product_name: string;
  patient_name: string;
  patient_phone: string;
  patient_timezone: string;
  dialpad_url: string;
  test_only: boolean;
  consumer_type: ConsumerType;
}

export interface RawShortLeadModel extends BaseShortLeadModel {
  join_history: object;
  imageSrc: string | Buffer;
  send_telemedicine_link: boolean;
  stop_message_detail: StopMessageDetails;
}

export interface ShortLeadModel extends BaseShortLeadModel {
  join_history: JoiningIdentityMsg[];
  imageSrc: string | Buffer;
  send_telemedicine_link: boolean;
  stop_message_detail: StopMessageDetails;
  patient_email: string;
  opt_in: boolean;
  logo: string;
}

export interface TelemedicineUrl {
  name: string;
  telemedicine_uri: string;
}

export interface GeoLocation {
  city: string;
  timezone: USTimezonesValues;
  zip: string;
}

export interface SurveyQuestion {
  field: string;
  question: {
    type: SurveyQuestionType;
    choices: null | {
      [key: number]: {
        title: string;
        subtitle: string | null;
        type: 'open_ended' | null;
      };
    };
    question: string;
  };
  value: { [key: number]: SurveyAnswer };
}

export interface IntakeFormQuestion {
  question: {
    answer_choices?: IntakeFormAnswerChoices[];
    text: string;
    description: string;
  };
  page_item_type: PageItemType;
  value: IntakeFormAnswer[];
}
export interface IntakeFormAnswer {
  texts?: string;
  type?: 'open_ended' | null;
  title?: string;
  subtitle?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export interface IntakeFormAnswerChoices {
  text: string;
  id: string;
}

export interface PatientQuestion {
  type: PatientQuestionType;
  description: string;
}

export interface Patient {
  uuid: string;
  first_name: string;
  last_name: string;
  phone: string;
  gender: Gender;
  dob: string;
  age: number;
  email: string;
  insurance_member_id: string;
  insurance_group_id: string;
  insurance_type: InsuranceType;
  insurance_carrier: string;
  insurance_plan: string;
  zip_code: ZipCode;
  timezone: USTimezonesValues;
  city: string;
}

export interface ZipCode {
  id: number;
  code: string;
  city: string;
  state: State;
}

export interface State {
  id: number;
  name: string;
  short_name: string;
}

export interface Clinician {
  uuid: string;
  full_name: string;
  phone: string;
  address: string;
  available_time_slot: string;
}

export interface EmailModel {
  subject: string;
  message: string;
  documents: string[];
  files: string[];
}

export interface EmailErrorModel {
  documents: ErrorType;
  files?: ErrorType;
  message: ErrorType;
  subject: ErrorType;
}

export interface Concierge {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
}
export type Customer = ISimpleObj;
export type Product = ISimpleObj;

export class LeadErrorModel {
  detail: ErrorType;
  non_field_errors: ErrorType;
  status: ErrorType;
  clinician: {
    full_name: ErrorType;
    phone: ErrorType;
    address: ErrorType;
    available_time_slot: ErrorType;
  };
}

export interface ILeadFormProps {
  lead: ConsumerUpdateModel | HCPUpdateModel;
  leadErrors: LeadErrorModel | HCPErrorModel;
  busy: boolean;
  onChange: (lead: ConsumerUpdateModel | HCPUpdateModel) => void;
  onErrorChange: (lead: LeadErrorModel | HCPErrorModel) => void;
}

export interface Interaction {
  name: string;
  type: 'action' | 'value';
  value?: string;
}

export interface HappituDataItem {
  agent_full_name: string;
  created_dt: string;
  interactions: Interaction[];
  tags: string[];
}
