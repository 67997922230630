import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { X } from 'react-feather';
import { useHistory } from 'react-router';

import { IDialogProps } from 'app/models/CommonModels';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {},
    dialogTitle: {
      padding: '25px 25px 0px 25px',
    },
    dialogContent: {
      padding: '20px 38px',
    },
    dialogActions: {
      padding: '0px 25px 25px 25px',
    },
    noPaddings: {
      padding: '0 !important',
    },
    closeButton: {
      padding: 0,
    },
    [theme.breakpoints.down('sm')]: {
      dialogTitle: {
        padding: 20,
      },
      dialogContent: {
        padding: 20,
      },
      closeButton: {
        position: 'absolute',
        left: 0,
      },
    },
    mb20: {
      marginBottom: 20,
    },
  })
);

interface IProps extends Omit<IDialogProps, 'onClose'> {
  open: boolean;
  title: string;
  message: string;
  onClose: (confirmed: boolean) => void;
  redirectTo?: string;
}

const ConfirmationDialog = ({ open, title, message, onClose, redirectTo = '' }: IProps) => {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({});
  const history = useHistory();

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      fullScreen={isMobileDevice}
      aria-labelledby="image-title"
    >
      <DialogTitle className={classes.noPaddings}>
        <div className={classes.dialogTitle}>
          <Grid
            container
            alignContent="center"
            justifyContent={isMobileDevice ? 'center' : 'space-between'}
            direction={isMobileDevice ? 'row-reverse' : 'row'}
          >
            <Box>
              <Typography
                variant="h4"
                color="secondary"
              >
                {title}
              </Typography>
            </Box>
            <IconButton
              onClick={() => onClose(false)}
              className={classes.closeButton}
            >
              <X
                width={35}
                height={35}
                color="#CBCBCB"
              />
            </IconButton>
          </Grid>
        </div>
      </DialogTitle>

      <DialogContent className={classes.noPaddings}>
        <div className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
            className={classes.mb20}
          >
            <Typography>{message}</Typography>
          </Grid>
        </div>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          autoFocus
          onClick={() => onClose(false)}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose(true);
            !!redirectTo && history.push(redirectTo);
          }}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
