import SparkMD5 from 'spark-md5';
import squareicon from 'squareicon';

import { HintVariant } from 'app/constants/HintVariant';

import { LeadStage } from './Lead';

type StagesResultsCount = { [key in keyof typeof LeadStage]: number } | null;

export type Order = 'asc' | 'desc';
export type ErrorType = string[] | undefined;

export enum LoadingState {
  Pending,
  Loading,
  Ready,
  Failed,
}

export enum SelectIDType {
  ID = 'id',
  UUID = 'uuid',
  URL = 'url',
  REDIRECT_URL = 'redirect_url',
}

export enum SelectNameType {
  Name = 'name',
  Text = 'text',
}

export const Environment = {
  Production: 'production',
  Staging: 'staging',
  Qa: 'qa',
  Development: 'development',
  Test: 'test',
  Sandbox: 'sandbox',
};

export const DateFormat = {
  Generic: 'yyyy-MM-dd',
  USDate: 'MM/dd/yyyy',
  USDateTime: 'MM/dd/yyyy hh:mm aa',
};

export enum Gender {
  Male = 10,
  Female = 20,
  Other = 30,
}

export type USTimezonesValues =
  | 'US/Michigan'
  | 'US/Eastern'
  | 'US/East-Indiana'
  | 'US/Indiana-Starke'
  | 'US/Central'
  | 'US/Arizona'
  | 'US/Mountain'
  | 'US/Pacific'
  | 'US/Alaska'
  | 'US/Aleutian'
  | 'US/Hawaii'
  | 'US/Samoa';

export const USTimezones = new Map<USTimezonesValues, string>([
  ['US/Michigan', 'US/Michigan GMT-4'],
  ['US/Eastern', 'US/Eastern GMT-4'],
  ['US/East-Indiana', 'US/East-Indiana GMT-4'],
  ['US/Indiana-Starke', 'US/Indiana-Starke GMT-5'],
  ['US/Central', 'US/Central GMT-5'],
  ['US/Mountain', 'US/Mountain GMT-6'],
  ['US/Arizona', 'US/Arizona GMT-7'],
  ['US/Pacific', 'US/Pacific GMT-7'],
  ['US/Alaska', 'US/Alaska GMT-8'],
  ['US/Aleutian', 'US/Aleutian GMT-9'],
  ['US/Hawaii', 'US/Hawaii GMT-10'],
  ['US/Samoa', 'US/Samoa GMT-11'],
]);

export const basicIconConfig: squareicon.Options = {
  hasher: SparkMD5.hash,
  colors: 2,
  pixels: 10,
  size: 80,
  padding: 0,
  symmetry: 'vertical',
  scheme: 'standard',
  background: 'white',
};

export interface IDialogProps {
  open: boolean;
  onClose: () => void;
}

export interface ISimpleObj {
  id: number;
  name: string;
}

export interface IResponseResult {
  success: boolean;
  msg?: string;
}

export interface IDataResult<T> {
  success: boolean;
  msg?: string;
  data: T;
}
export interface NotifierModel {
  open: boolean;
  message: string;
  variant: keyof typeof HintVariant;
}

export interface Paginator {
  count: number;
  next: number | null;
  previous: number | null;
  queryParamName: string;
}

export interface RawPagingResult<R> {
  paginator: {
    count: number;
    next: number | null;
    previous: number | null;
    query_param: string;
  };
  results: R[];
  stages_results_count: StagesResultsCount;
}

export interface PaginatorTwilioMesssages<T> {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  items: T[];
  nextPage(): Promise<PaginatorTwilioMesssages<T>>;
  prevPage(): Promise<PaginatorTwilioMesssages<T>>;
}

export class PagingResult<T> implements Paginator {
  count: number;
  next: number | null;
  previous: number | null;
  queryParamName: string;
  results: T[];
  stagesResultsCount: StagesResultsCount;

  /** get items count till the current page */
  public get currentCount(): number {
    if (!!this.previous && this.previous > 0) {
      return this.itemsPerPage * this.previous + this.results.length;
    }

    return this.results.length;
  }

  public get pagesCount(): number {
    return Math.ceil(this.count / this.itemsPerPage);
  }

  public get nextPageParam(): string {
    return this.next ? `${this.queryParamName}=${this.next}` : '';
  }

  public get previousPageParam(): string {
    return this.previous ? `${this.queryParamName}=${this.previous}` : '';
  }

  /** get current page number */
  public get current(): number {
    if (this.next) {
      return this.next - 1;
    } else if (this.previous) {
      return this.previous + 1;
    }

    return 1;
  }

  /** get items per page */
  private get itemsPerPage(): number {
    if (!this.next && !!this.previous && this.previous > 0) {
      return (this.count - this.results.length) / this.previous;
    }

    return this.results.length;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
export function parsePagingResult<T>(raw: RawPagingResult<T | any>, parseListCb?: Function): PagingResult<T> {
  const res = new PagingResult<T>();

  res.count = raw.paginator.count;
  res.next = raw.paginator.next;
  res.previous = raw.paginator.previous;
  res.queryParamName = raw.paginator.query_param;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  res.results = parseListCb ? parseListCb(raw.results) : (raw.results as any[]);
  res.stagesResultsCount = raw.stages_results_count || null;

  return res;
}

export function getGenderStr(gender: Gender | null | undefined) {
  switch (gender) {
    case Gender.Male:
      return 'Male';
    case Gender.Female:
      return 'Female';
    case Gender.Other:
      return 'Other';
    default:
      return '-';
  }
}

export interface UserType {
  user_id: string;
  email: string;
  type: string;
}
