import { createTheme } from '@material-ui/core/styles';

import InterRegular from 'assets/fonts/Inter-Regular.woff2';

import { RxButton } from './RxButton';
import { RxFormLabel } from './RxFormLabel';
import { RxTableCell } from './RxTableCell';
import { RxTooltip } from './RxTooltip';

declare module '@material-ui/core/styles/createPalette' {
  type StatusColors = { bg: string; text: string };
  interface Palette {
    status: {
      yellow: StatusColors;
      green: StatusColors;
      blue: StatusColors;
    };
  }
}

const RxDefineAdminTheme = {
  name: 'Green',
  palette: {
    primary: {
      main: '#6918D0',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#1b202f',
      contrastText: '#FFF',
    },
    success: {
      main: '#5FA560',
      contrastText: '#FFF',
    },
    warning: {
      main: '#E9921F',
      contrastText: '#FFF',
    },
    info: {
      main: '#333333',
      contrastText: '#FFF',
    },
    error: {
      main: '#FF5E5E',
      contrastText: '#FFF',
    },
    disabled: {
      main: 'rgba(0, 0, 0, 0.5)',
      contrastText: '#FFF',
    },
    text: {
      primary: '#333',
      secondary: '#666',
    },
    status: {
      yellow: {
        bg: '#FDF5B3',
        text: '#C77E1E',
      },
      green: {
        bg: '#ECFDEC',
        text: '#5FA560',
      },
      blue: {
        bg: '#EBF9FD',
        text: '#2DC6E8',
      },
    },
  },
};

const theme = (variant) => {
  const muiTheme = createTheme({
    palette: {
      ...variant.palette,
    },
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1440,
      },
    },
    typography: {
      fontFamily: ['InterRegular'].join(','),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      h1: {
        fontSize: '2.5rem',
        fontWeight: 600,
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 600,
      },
      h3: {
        fontSize: '1.75rem',
        fontWeight: 600,
      },
      h4: {
        fontSize: '1.5rem',
        fontWeight: 600,
      },
      h5: {
        fontSize: '1.25rem',
        fontWeight: 600,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 600,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
      // subtitle2: {
      //   fontSize: 14,
      // },
      button: {
        textTransform: 'none',
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  });

  muiTheme.overrides = {
    MuiCssBaseline: {
      '@global': {
        '@font-face': {
          fontFamily: 'InterRegular',
          fontStyle: 'normal',
          fontDisplay: 'swap',
          fontWeight: 400,
          src: `
          url(${InterRegular}) format('woff2')
        `,
          unicodeRange:
            'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
        },
      },
    },
    MuiTableBody: {
      root: {
        '& td': {
          fontSize: 16,
        },
      },
    },
    MuiTooltip: RxTooltip,
    MuiButton: RxButton(muiTheme),
    MuiFormLabel: RxFormLabel(),
    MuiTableCell: RxTableCell,
  };

  return {
    ...muiTheme,
    fontSizes: {
      xs: '10px',
      sm: '12px',
      md: '14px',
      lg: '16px',
      xl: '18px',
    },
    body: {
      background: '#F5F5F5',
    },
  };
};

export default theme(RxDefineAdminTheme);
