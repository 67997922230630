import { Grid } from '@material-ui/core';
import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.palette.common.white};
`;

const Footer = () => (
  <Wrapper>
    <Grid
      container
      spacing={0}
    ></Grid>
  </Wrapper>
);

export default Footer;
