import { LinearBackoff, Websocket, WebsocketBuilder } from 'websocket-ts/lib';

import config from 'app/config';

export default class NotificationsService {
  ws: Websocket;

  openWsConnection(loginToken: string, forceReopen = false) {
    if (this.ws && !forceReopen) {
      return this.ws;
    }

    this.ws = new WebsocketBuilder(config.WEBSOCKET_URL)
      .onOpen(() => {
        this.ws?.send(JSON.stringify({ action: 'cdp_auth', token: loginToken }));
      })
      .onClose(() => {})
      .withBackoff(new LinearBackoff(0, 1000, 10000))
      .build();

    return this.ws;
  }
}
