import { parseISO } from 'date-fns';

import { toPhoneNumberWithoutCharacters } from 'app/utils/PhoneNumberHelpers';

import { Clinician, InsuranceType, LeadCreationType, LeadFlowType, PatientQuestionType, PostLeadStatus } from '.';
import { USTimezones } from '../CommonModels';

export function getLeadFlowTypeStr(flowType: LeadFlowType | null | undefined): string {
  switch (flowType) {
    case LeadFlowType.Concierge:
      return 'Concierge';
    case LeadFlowType.Telemedicine:
      return 'Telemedicine';
    default:
      return 'None';
  }
}

export function getLeadCreationTypeStr(creationType: LeadCreationType | null | undefined): string {
  switch (creationType) {
    case LeadCreationType.AUTOMATICALLY:
      return 'Automatically';
    case LeadCreationType.INCOMING_CALLER:
      return 'Incoming caller';
    case LeadCreationType.WEBCHAT:
      return 'Webchat';
    default:
      return 'None';
  }
}

export function getAdditionalQuestionStr(ad: PatientQuestionType) {
  switch (ad) {
    case PatientQuestionType.Product:
      return 'Product Questions';
    case PatientQuestionType.ClinicianScheduling:
      return 'Clinician Scheduling Questions';
    case PatientQuestionType.Condition:
      return 'Condition Questions';
    case PatientQuestionType.Cost:
      return 'Cost Questions';
    case PatientQuestionType.SideEffects:
      return 'Side Effects Questions';
    case PatientQuestionType.Other:
    default:
      return 'Other Questions';
  }
}

export function getISODate(date: string | null, options = undefined) {
  return date ? parseISO(date, options) : new Date();
}

export function parseClinicianUpdate(sur: Clinician): Clinician {
  return {
    ...sur,
    phone: sur.phone ? toPhoneNumberWithoutCharacters(sur.phone) : null,
  } as Clinician;
}

export function getInsuranceName(type: InsuranceType): string {
  switch (type) {
    case InsuranceType.EPO:
      return 'Exclusive Provider Organization (EPO)';
    case InsuranceType.HMO:
      return 'Health Maintenance Organization (HMO)';
    case InsuranceType.POS:
      return 'Point of Service (POS)';
    case InsuranceType.PPO:
      return 'Preferred Provider Organization (PPO)';
    default:
      return 'None';
  }
}

export function getPostLeadStatusStr(status: PostLeadStatus) {
  switch (status) {
    case PostLeadStatus.QuestionnaireSent:
      return 'Questionnaire sent';
    case PostLeadStatus.QuestionnaireNotSubmitted:
      return 'Questionnaire not submitted';
    case PostLeadStatus.QuestionnaireResent:
      return 'Questionnaire resent';
    case PostLeadStatus.RxPrescribed:
      return 'Rx prescribed';
    case PostLeadStatus.AlternativeRxPrescribed:
      return 'Alt rx prescribed';
    case PostLeadStatus.NoRxPrescribed:
      return 'No Rx prescribed';
    case PostLeadStatus.NoAppointment:
      return 'No appointment';
    case PostLeadStatus.NoPostConsult:
      return 'No post consult status';
    case PostLeadStatus.UndefinedRxPrescribed:
      return 'Undefined Rx Prescribed';
    default:
      return `None`;
  }
}

export function parseTimezone(timezone: string) {
  let result: string | null = null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  USTimezones.forEach((tz, index) => {
    if (index == timezone) {
      result = timezone;
      return result;
    }
  });

  return result;
}

export const userDisplayName = (firstName, lastName, defaultValue = '-') => {
  return [firstName, lastName].filter(Boolean).join(' ') || defaultValue;
};
