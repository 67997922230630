import { makeAutoObservable } from 'mobx';

import { HintVariant } from 'app/constants/HintVariant';

export interface NotifierModel {
  open: boolean;
  message: string;
  variant: keyof typeof HintVariant;
}

export class Notifier {
  notifier: NotifierModel = {
    open: false,
    message: '',
    variant: 'info',
  };

  constructor() {
    makeAutoObservable(this);
  }

  notify(msg: string, v: keyof typeof HintVariant = 'error') {
    this.notifier = { open: true, message: msg, variant: v };
  }

  resetNotifier = () => {
    this.notifier = { open: false, message: '', variant: 'info' };
  };
}
