import { makeAutoObservable } from 'mobx';
import { getEnv } from 'mobx-easy';

import { IDataResult } from 'app/models/CommonModels';
import { PreviewUploadModel } from 'app/models/FileModel';
import { RootEnv } from 'app/stores/config/CreateStore';
import { EmailErrorModel, EmailModel } from 'app/models/Lead';

export interface ConfirmDialogModel {
  open: boolean;
  title: string;
  onSubmit?: () => void;
  onClose?: () => void;
}

const initConfirmDialog: ConfirmDialogModel = {
  open: false,
  title: '',
  onSubmit: () => {},
  onClose: () => {},
};

export class EmailDialog {
  confirmDialog = initConfirmDialog;

  constructor() {
    makeAutoObservable(this);
  }

  private reset() {
    this.confirmDialog = initConfirmDialog;
  }

  show(parameters: Omit<ConfirmDialogModel, 'open' | 'onSubmit'>) {
    this.confirmDialog = {
      open: true,
      ...parameters,
    };
  }

  hide() {
    this.reset();
  }

  setSubmit(submitMethod: () => void) {
    this.confirmDialog.onSubmit = submitMethod;
  }

  setClose(closeMethod: () => void) {
    this.confirmDialog.onClose = closeMethod;
  }

  async attachFile(bodyFormData: FormData): Promise<IDataResult<PreviewUploadModel>> {
    try {
      const { fileService } = getEnv<RootEnv>();

      const { data } = await fileService.uploadFile(bodyFormData);

      return { success: true, data: data };
    } catch (error) {
      // @ts-ignore
      return { success: false, data: null };
    }
  }

  async sendEmail(
    uuid: string,
    email: EmailModel
  ): Promise<{
    success: boolean;
    msg: string;
    formErrors?: EmailErrorModel | null;
  }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      await leadService.sendEmail(uuid, email);

      return {
        success: true,
        msg: 'Email has been successfully sent.',
      };
    } catch (error) {
      return {
        success: false,
        msg: '',
        formErrors: error?.response?.data,
      };
    }
  }
}
