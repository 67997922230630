import { Client as TwilioConversationsClient } from '@twilio/conversations';
import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import { Websocket } from 'websocket-ts/lib';
import * as Sentry from '@sentry/browser';

import { ErrorMsgs, SuccessMsgs } from 'app/models/MsgModels';
import { RootEnv } from 'app/stores/config/CreateStore';
import RootStore from 'app/stores/RootStore';
import { Sleep } from 'app/utils/Sleep';

import { SmsChat } from './SmsChat';
import { SmsChats } from './SmsChats';
import { WebChat } from './WebChat';
import { WebChats } from './WebChats';

export default class ChatsStore {
  //stores
  smsChatsStore: SmsChats;
  smsChatStore: SmsChat;
  webChatsStore: WebChats;
  webChatStore: WebChat;

  //props
  conciergeIdentity = '';
  conciergeChatToken = '';
  conversationalClient: TwilioConversationsClient | null = null;

  // @ts-ignore
  ws: Websocket = null;

  constructor() {
    makeAutoObservable(this);

    //init stores
    this.smsChatsStore = new SmsChats(this);
    this.smsChatStore = new SmsChat();
    this.webChatsStore = new WebChats(this);
    this.webChatStore = new WebChat();
  }

  async openCommunicationsChannel(conversationalToken?: string, forceReopen = false) {
    try {
      const { notificationsService } = getEnv<RootEnv>();
      const {
        dataStores: { authStore },
      } = getRoot<RootStore>();

      if (!conversationalToken && authStore.isConcierge) {
        const { success, token } = await this.getConversationalToken();
        if (!success) {
          return;
        }

        conversationalToken = token;
      }

      // @ts-ignore
      const client = new TwilioConversationsClient(conversationalToken);
      const ws = notificationsService.openWsConnection(authStore.authData.access, forceReopen);

      runInAction(() => {
        this.conversationalClient = client;
        this.ws = ws;
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async getConversationalToken(): Promise<{
    success: boolean;
    msg: string;
    token?: string;
  }> {
    try {
      const { chatService } = getEnv<RootEnv>();
      const { data } = await chatService.getChatsToken();

      if (data.token) {
        runInAction(() => {
          this.conciergeChatToken = data.token;
          this.conciergeIdentity = data.identity;
        });
      }

      return {
        success: !!data.token,
        msg: data.token ? SuccessMsgs.TokenLoaded : ErrorMsgs.TokenNotLoadedShort,
        token: data.token,
      };
    } catch (error) {
      return {
        success: false,
        msg: ErrorMsgs.TokenNotLoaded,
        token: '',
      };
    }
  }

  async initChat(luuid: string): Promise<{
    success: boolean;
    msg: string;
    sid: string;
  }> {
    try {
      const { chatService } = getEnv<RootEnv>();
      const { data } = await chatService.initChat(luuid);

      if (data.blocked) {
        return {
          success: false,
          msg: ErrorMsgs.ChatInitiatedByOther,
          sid: '',
        };
      }

      const smsStore = getRoot<RootStore>().dataStores.chatsStore.smsChatsStore;
      const consumerStore = getRoot<RootStore>().dataStores.leadStore.consumerStore;

      smsStore.setPendingConversation({
        ...data,
        conversation_sid: data.sid,
      });

      //open connection if it was not opened
      await this.openCommunicationsChannel(data.token);

      //start conversation
      await consumerStore.loadShortLeadInfo(data.sid).then(() =>
        Sleep(1000)
          .then(() => smsStore.loadConversation(data.sid))
          .then(() => smsStore.setActiveChat(data.sid, false))
          .then(() => smsStore.setAllMsgsAsRead(data.sid))
      );

      return { success: true, msg: SuccessMsgs.ChatInitiated, sid: data.sid };
    } catch (error) {
      return {
        success: false,
        msg:
          error?.response?.data === ErrorMsgs.ConvAlreadyExists
            ? ErrorMsgs.NumberUsedToAnotherChat
            : ErrorMsgs.ChatNotBeInitiated,
        sid: '',
      };
    }
  }

  //TODO: obsolated
  async loadConversations(): Promise<{
    success: boolean;
    msg: string;
    token?: string;
  }> {
    try {
      const { chatService } = getEnv<RootEnv>();
      const { data } = await chatService.getChatsToken();

      if (data.token) {
        runInAction(() => {
          this.conciergeChatToken = data.token;
          this.conciergeIdentity = data.identity;
        });
      }

      return {
        success: !!data.token,
        msg: data.token ? SuccessMsgs.TokenLoaded : ErrorMsgs.TokenNotLoadedShort,
        token: data.token,
      };
    } catch (error) {
      return {
        success: false,
        msg: ErrorMsgs.TokenNotLoaded,
        token: '',
      };
    }
  }
}
