import { toPhoneNumberWithoutCharacters } from 'app/utils/PhoneNumberHelpers';

import { HCPModel, HCPRequestModel, HCPStatus, HCPUpdateModel, HCPViewActionTaken } from '.';
import { getISODate, parseTimezone } from '../Lead';

export function getHCPStatusStr(status: HCPStatus | null | undefined): string {
  switch (status) {
    case HCPStatus.NONE:
      return 'None';
    case HCPStatus.GATHERING_INGO:
      return 'Gathering HCP Info';
    case HCPStatus.ONGOING_SUPPORT:
      return 'Ongoing Support';
    case HCPStatus.UNREACHABLE:
      return 'HCP Unreachable';
    case HCPStatus.UNABLE_TO_ENGAGE:
      return 'Unable to Engage';
    case HCPStatus.ASSISTED_WITH_OBTAINING_SAMPLE:
      return 'Assisted with Obtaining Sample';
    case HCPStatus.DIRECT_TO_SALES_REPRESENTATIVE_CONTACT:
      return 'Direct to Sales Representative Contact';
    case HCPStatus.INTERNATIONAL_INQUIRY_RESPONSE_PROVIDED:
      return 'International Inquiry Response Provided';
    case HCPStatus.PATIENT_SUPPORT_PROGRAM_INFORMATION_PROVIDED:
      return 'Patient Support Program Information Provided';
    case HCPStatus.PRODUCT_INFORMATION_PROVIDED:
      return 'Product Information Provided';
    case HCPStatus.REDIRECTED_TO_MEDICAL_INFORMATION:
      return 'Redirected to Medical Information';
    case HCPStatus.REIMBURSEMENT_INFORMATION_PROVIDED:
      return 'Reimbursement Information Provided';
    default:
      return 'None';
  }
}

export function getHCPViewActionTaken(status: HCPStatus): HCPViewActionTaken {
  switch (status) {
    case HCPStatus.NONE:
      return HCPViewActionTaken.None;
    case HCPStatus.ASSISTED_WITH_OBTAINING_SAMPLE:
      return HCPViewActionTaken.ASSISTED_WITH_OBTAINING_SAMPLE;
    case HCPStatus.DIRECT_TO_SALES_REPRESENTATIVE_CONTACT:
      return HCPViewActionTaken.DIRECT_TO_SALES_REPRESENTATIVE_CONTACT;
    case HCPStatus.INTERNATIONAL_INQUIRY_RESPONSE_PROVIDED:
      return HCPViewActionTaken.INTERNATIONAL_INQUIRY_RESPONSE_PROVIDED;
    case HCPStatus.PATIENT_SUPPORT_PROGRAM_INFORMATION_PROVIDED:
      return HCPViewActionTaken.PATIENT_SUPPORT_PROGRAM_INFORMATION_PROVIDED;
    case HCPStatus.PRODUCT_INFORMATION_PROVIDED:
      return HCPViewActionTaken.PRODUCT_INFORMATION_PROVIDED;
    case HCPStatus.REDIRECTED_TO_MEDICAL_INFORMATION:
      return HCPViewActionTaken.REDIRECTED_TO_MEDICAL_INFORMATION;
    case HCPStatus.REIMBURSEMENT_INFORMATION_PROVIDED:
      return HCPViewActionTaken.REIMBURSEMENT_INFORMATION_PROVIDED;
    default:
      return HCPViewActionTaken.None;
  }
}

export function parseHCP(lead: HCPModel): HCPUpdateModel {
  const res = {
    shortUuid: lead.uuid?.substring(0, 4) || '-',
    ...lead,
    hcp_status: lead?.hcp_status ? lead.hcp_status : HCPStatus.NONE,
    hcp_view_status: getHCPViewActionTaken(lead.hcp_status),
    assigned_dt: getISODate(lead.assigned_dt),
    health_care_provider: {
      ...lead.health_care_provider,
      age: lead.health_care_provider.age ? lead.health_care_provider.age : null,
      phone: lead.health_care_provider?.phone ?? '',
      timezone: parseTimezone(lead.health_care_provider.timezone),
      zip_code: lead.health_care_provider?.zip_code?.code || '',
    },
    zip_code_state: lead.health_care_provider?.zip_code,
  } as unknown as HCPUpdateModel;

  return res;
}

export function getHCPStatus(viewStatus: HCPViewActionTaken): HCPStatus {
  switch (viewStatus) {
    case HCPViewActionTaken.None:
      return HCPStatus.GATHERING_INGO;
    case HCPViewActionTaken.ASSISTED_WITH_OBTAINING_SAMPLE:
      return HCPStatus.ASSISTED_WITH_OBTAINING_SAMPLE;
    case HCPViewActionTaken.DIRECT_TO_SALES_REPRESENTATIVE_CONTACT:
      return HCPStatus.DIRECT_TO_SALES_REPRESENTATIVE_CONTACT;
    case HCPViewActionTaken.INTERNATIONAL_INQUIRY_RESPONSE_PROVIDED:
      return HCPStatus.INTERNATIONAL_INQUIRY_RESPONSE_PROVIDED;
    case HCPViewActionTaken.PATIENT_SUPPORT_PROGRAM_INFORMATION_PROVIDED:
      return HCPStatus.PATIENT_SUPPORT_PROGRAM_INFORMATION_PROVIDED;
    case HCPViewActionTaken.PRODUCT_INFORMATION_PROVIDED:
      return HCPStatus.PRODUCT_INFORMATION_PROVIDED;
    case HCPViewActionTaken.REDIRECTED_TO_MEDICAL_INFORMATION:
      return HCPStatus.REDIRECTED_TO_MEDICAL_INFORMATION;
    case HCPViewActionTaken.REIMBURSEMENT_INFORMATION_PROVIDED:
      return HCPStatus.REIMBURSEMENT_INFORMATION_PROVIDED;
    default:
      return HCPStatus.GATHERING_INGO;
  }
}

export function parseHCPUpdate(sur: HCPUpdateModel): HCPRequestModel {
  return {
    ...sur,
    hcp_status:
      sur.hcp_status === HCPStatus.ONGOING_SUPPORT ||
      sur.hcp_status === HCPStatus.UNREACHABLE ||
      sur.hcp_status === HCPStatus.UNABLE_TO_ENGAGE
        ? sur.hcp_status
        : getHCPStatus(sur.hcp_view_status),
    stage: sur.stage,
    health_care_provider: {
      ...sur.health_care_provider,
      timezone: sur.health_care_provider.timezone || undefined,
      phone: sur.health_care_provider?.phone ? toPhoneNumberWithoutCharacters(sur.health_care_provider?.phone) : '',
    },
  } as HCPRequestModel;
}
