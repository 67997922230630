import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';

import { RootEnv } from 'app/stores/config/CreateStore';
import RootStore from 'app/stores/RootStore';
import { HappituDataItem } from 'app/models/Lead';

export const INIT_HAPPITU_DATA: HappituDataItem[] = [];

export class HappituDataStore {
  isLoading = false;
  happituData: HappituDataItem[] = INIT_HAPPITU_DATA;

  constructor() {
    makeAutoObservable(this);
  }

  async loadHappituData(uuid: string, isHCP = false) {
    this.isLoading = true;

    try {
      const { leadService } = getEnv<RootEnv>();

      const { data } = await leadService.getHappituData(uuid, isHCP);

      runInAction(() => {
        this.happituData = data;
      });
    } catch (error) {
      const {
        uiStores: { notifierStore },
      } = getRoot<RootStore>();

      const errorMsg = error?.detail?.[0] || 'Happitu Data has not been loaded. Please check data and try again.';

      notifierStore.notify(errorMsg);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  resetHappituData() {
    this.happituData = INIT_HAPPITU_DATA;
  }
}
