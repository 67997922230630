import { AxiosResponse } from 'axios';

import Axios from 'app/data/axios';

import { Materials } from '../models/CustomerModels';

export default class CustomerService {
  async getCustomersMaterials(): Promise<AxiosResponse<Materials>> {
    return await Axios.get('/customers/materials/');
  }
}
