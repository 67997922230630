import { CssBaseline } from '@material-ui/core';
import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const AuthLayout = ({ children }: React.PropsWithChildren) => (
  <Root>
    <CssBaseline />
    <GlobalStyle />
    {children}
  </Root>
);

export default AuthLayout as unknown as React.ComponentClass;
