import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import squareicon from 'squareicon';

import { StopMessageStatus } from 'app/models/ChatModels';
import { HistoryResult } from 'app/models/HistoryModels';
import { RootEnv } from 'app/stores/config/CreateStore';
import RootStore from 'app/stores/RootStore';
import {
  ConsumerErrorModel,
  ConsumerModel,
  ConsumerStatus,
  ConsumerUpdateModel,
  parseSurveyUpdate,
} from 'app/models/Consumer';

import { basicIconConfig } from '../../../models/CommonModels';
import { getError as getErrorMsg } from '../../../models/ErrorModels';
import { Clinician, Customer, parseClinicianUpdate, ShortLeadModel } from '../../../models/Lead';

export class Consumer {
  lead: ConsumerModel;
  history: HistoryResult | null = null;
  customers: Customer[];
  shortLeadInfo: ShortLeadModel | null = null;
  busy = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isUnsubscribedForChats(): boolean {
    return this.shortLeadInfo?.stop_message_detail?.unsubscribed || false;
  }

  get isOptedInForChats(): boolean {
    return !!this.shortLeadInfo?.opt_in;
  }

  get sendingStatus(): StopMessageStatus {
    return this.shortLeadInfo?.stop_message_detail?.stop_sending_status || StopMessageStatus.NotSend;
  }

  get stopMessage(): string {
    return this.shortLeadInfo?.stop_message_detail?.stop_message || '';
  }

  get patientEmail(): string {
    return this.lead.patient.email;
  }

  async loadShortLeadInfo(sid: string): Promise<{ success: boolean; msg: string | object }> {
    try {
      const { leadService, chatService } = getEnv<RootEnv>();
      const {
        dataStores: { chatDocumentsStore, chatsStore },
      } = getRoot<RootStore>();

      await chatService.startSmsConversationBySid(sid); // move to chats
      const { data: rawData } = await leadService.getShortLeadInfo(sid);
      await chatDocumentsStore.loadDocuments(rawData.conversation_sid);
      await chatsStore.smsChatsStore.fetchTelemedicineUrl(rawData.survey_uuid);

      const data = {
        ...rawData,
        join_history: Object.keys(rawData.join_history).map((key) => ({
          joiningDate: new Date(key),
          identityName: rawData.join_history[key],
        })),
      } as ShortLeadModel;

      squareicon({ id: data.conversation_sid, ...basicIconConfig }, (err, img) => {
        data.imageSrc = img;
      });

      runInAction(() => {
        this.shortLeadInfo = data;
      });

      return { success: true, msg: '' };
    } catch (error) {
      runInAction(() => {
        this.shortLeadInfo = null;
      });

      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
      };
    }
  }

  async loadLead(uuid: string): Promise<{
    success: boolean;
    msg: string | object;
    status: ConsumerStatus;
  }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getLead(uuid);

      runInAction(() => {
        this.lead = data;
      });

      return { success: true, msg: '', status: data.status };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
        status: error?.response?.status,
      };
    }
  }

  async setLeadStatus(uuid: string, status: ConsumerStatus): Promise<{ success: boolean; msg: string | object }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      await leadService.updateLeadStatus(uuid, status);

      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
      };
    }
  }

  async reassigneLead(uuid: string): Promise<{ success: boolean; msg: string | object }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      await leadService.reassigneLead(uuid);

      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
      };
    }
  }

  async resendQuestionnaireLink(uuid: string): Promise<{ success: boolean; msg: string | object }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      await leadService.resendQuestionnaireLink(uuid);

      runInAction(() => {
        this.lead = {
          ...this.lead,
        };
      });

      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
      };
    }
  }

  async updateLead(
    uuid: string,
    lead: ConsumerUpdateModel
  ): Promise<{
    success: boolean;
    msg: string;
    formErrors?: ConsumerErrorModel | null;
  }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.updateLead(uuid, parseSurveyUpdate(lead));

      runInAction(() => {
        this.lead = data;
      });

      return { success: true, msg: 'Survey has been successfully updated.' };
    } catch (error) {
      return {
        success: false,
        msg: '',
        formErrors: error?.response?.data,
      };
    }
  }

  async loadCustomers(): Promise<boolean> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getCustomersList();

      runInAction(() => {
        this.customers = data.results;
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async loadActiveCustomers(): Promise<Customer[]> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getCustomersList(true);

      return data.results;
    } catch (error) {
      const {
        uiStores: { notifierStore },
      } = getRoot<RootStore>();

      notifierStore.notify('Customers has not been loaded. Pelease try again later');

      return [];
    }
  }

  async loadHistory(luuid: string, params: string | undefined): Promise<boolean> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getHistory(luuid, params);

      runInAction(() => {
        if (data?.result) {
          this.history = {
            next_cursor: data.next_cursor,
            period: data.period,
            // @ts-ignore
            result: params?.includes('cursor') ? this.history.result.concat(data.result) : data.result,
          };
        } else {
          // @ts-ignore
          this.history.next_cursor = null;
        }
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async addDoctor(
    uuid: string,
    clinician: Clinician
  ): Promise<{
    success: boolean;
    msg: string;
    formErrors?: ConsumerErrorModel | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
  }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.addDoctor(uuid, parseClinicianUpdate(clinician));

      return {
        success: true,
        msg: 'HCP has been successfully added.',
        data: data,
      };
    } catch (error) {
      return {
        success: false,
        msg: '',
        formErrors: error?.response?.data,
      };
    }
  }

  async updateDoctor(
    uuid: string,
    clinicianUuid: string,
    clinician: Clinician
  ): Promise<{
    success: boolean;
    msg: string;
    formErrors?: ConsumerErrorModel | null;
  }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      await leadService.updateDoctor(uuid, clinicianUuid, parseClinicianUpdate(clinician));

      return { success: true, msg: 'HCP has been successfully updated.' };
    } catch (error) {
      return {
        success: false,
        msg: '',
        formErrors: error?.response?.data,
      };
    }
  }

  async removeDoctor(
    uuid: string,
    clinicianUuid: string
  ): Promise<{
    success: boolean;
    msg: string;
    formErrors?: ConsumerErrorModel | null;
  }> {
    try {
      const { leadService } = getEnv<RootEnv>();
      await leadService.removeDoctor(uuid, clinicianUuid);

      return { success: true, msg: 'HCP has been removed successfully.' };
    } catch (error) {
      return {
        success: false,
        msg: '',
        formErrors: error?.response?.data,
      };
    }
  }

  async changeOptIn(
    sid: string,
    uuid: string,
    checked: boolean
  ): Promise<{
    success: boolean;
    msg: string;
    formErrors?: ConsumerErrorModel | null;
  }> {
    try {
      this.busy = true;
      const { leadService } = getEnv<RootEnv>();
      const {
        data: { details },
      } = await leadService.changeOptIn(uuid, checked);
      const { data: rawData } = await leadService.getShortLeadInfo(sid);

      const data = {
        ...rawData,
        join_history: Object.keys(rawData.join_history).map((key) => ({
          joiningDate: new Date(key),
          identityName: rawData.join_history[key],
        })),
      } as ShortLeadModel;

      runInAction(() => {
        this.shortLeadInfo = data;
      });

      return { success: true, msg: details };
    } catch (error) {
      return {
        success: false,
        msg: '',
        formErrors: error?.response?.data,
      };
    } finally {
      this.busy = false;
    }
  }
}
