import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv } from 'mobx-easy';

import { FollowupLogResult } from 'app/models/FollowupLogModels';
import { RootEnv } from 'app/stores/config/CreateStore';
import { parsePagingResult } from 'app/models/CommonModels';

export class FollowupLog {
  followupLogs: FollowupLogResult;

  constructor() {
    makeAutoObservable(this);
  }

  async loadFollowupLogs(leadUuid: string): Promise<boolean> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.loadFollowUpLogs(leadUuid);

      runInAction(() => {
        const temp = parsePagingResult(data);
        temp.results = temp.results.reverse();
        this.followupLogs = temp as FollowupLogResult;
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async addFollowupLog(leadUuid: string, message: string): Promise<boolean> {
    try {
      const { leadService } = getEnv<RootEnv>();

      await leadService.logFollowUpRecord(leadUuid, message);
      await this.loadFollowupLogs(leadUuid);

      return true;
    } catch (error) {
      return false;
    }
  }
}
