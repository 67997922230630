import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv } from 'mobx-easy';

import { parseRowQuestionsCategories, QuestionsCategory } from 'app/models/LeadQuestionsModels';
import { RootEnv } from 'app/stores/config/CreateStore';

export class LeadQuestions {
  categories: QuestionsCategory[];

  constructor() {
    makeAutoObservable(this);
  }

  async loadQuestions(survey_uuid: string): Promise<QuestionsCategory[]> {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.loadSurveyQuestions(survey_uuid);

      runInAction(() => {
        this.categories = parseRowQuestionsCategories(data);
      });

      return this.categories;
    } catch (error) {
      return [];
    }
  }
}
