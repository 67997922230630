import { Chat } from 'app/models/Chat';
import { WebConversation } from 'app/models/ChatModels';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sortChatMessagesByDate(a: any, b: any): number {
  const date1 = a.joiningDate ? a.joiningDate : new Date(a.state.timestamp);

  const date2 = b.joiningDate ? b.joiningDate : new Date(b.state.timestamp);

  return date1 - date2;
}

export function sortChatsByLastMessageDate(a: WebConversation | Chat, b: WebConversation | Chat): number {
  const date1 = new Date(a.last_message_dt);

  const date2 = new Date(b.last_message_dt);

  return date2.getTime() - date1.getTime();
}
