import { AxiosResponse } from 'axios';

import Axios from 'app/data/axios';
import { HCPFlowModel, HCPModel, HCPRequestModel, HCPsResult, HCPStatus } from 'app/models/HCP';
import { HistoryResult } from 'app/models/HistoryModels';
import { RawPagingResult } from 'app/models/CommonModels';

export default class HCPService {
  async getHCP(uuid: string): Promise<AxiosResponse<HCPModel>> {
    return await Axios.get(`surveys/hcp-surveys/${uuid}/`);
  }

  async getHCPs(params?: string | undefined): Promise<AxiosResponse<RawPagingResult<HCPsResult>>> {
    return await Axios.get(`surveys/hcp-surveys/?${params ? params : ''}`);
  }

  async updateHCP(uuid: string, survey: HCPRequestModel) {
    return await Axios.put(`surveys/hcp-surveys/${uuid}/`, survey);
  }

  async getHistory(luuid: string, params?: string | undefined): Promise<AxiosResponse<HistoryResult>> {
    return await Axios.get(`/surveys/hcp-surveys/${luuid}/history/?${params ? params : ''}`);
  }

  async updateLeadStatus(uuid: string, hcp_status: HCPStatus) {
    return await Axios.patch(`/surveys/hcp-surveys/${uuid}/`, { hcp_status });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async reassignLead(uuid: string): Promise<AxiosResponse<any>> {
    return await Axios.post(`/surveys/hcp-surveys/${uuid}/assign/`);
  }

  async getHCPFlows(uuid: string): Promise<AxiosResponse<RawPagingResult<HCPFlowModel>>> {
    return await Axios.get(`/surveys/hcp-surveys/get-hcp-flows/?customer_uuid=${uuid}`);
  }
}
