export type MsgErrorType = { message: string };
export type DataErrorType = {
  data:
    | string
    | object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | any[]
    | {
        detail?: string;
        non_field_error?: string;
        token?: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error_messages?: any;
      };
};

export type UnsuccessfulResponseType = MsgErrorType | DataErrorType;
export type ServerErrorType = { response: { data: DataErrorType | object } } | MsgErrorType;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UIErrorType = string | object | undefined | any[];

/** response === 200 and 'success === false' */
export const getError = (
  {
    response,
  }: {
    response: UnsuccessfulResponseType;
  },
  skipDefault = false
): UIErrorType | null => {
  let result: UIErrorType = 'Something went wrong. Please try again later.';

  if (response && 'data' in response) {
    const data = response.data;
    if (typeof data === 'string') {
      result = data;
    } else if ('detail' in data) {
      result = data.detail;
    } else if ('non_field_error' in data) {
      result = data.non_field_error;
    } else if ('error_messages' in data) {
      result = data.error_messages;
    } else if ('token' in data) {
      result = data.token;
    } else if (skipDefault) {
      return null;
    }
  }

  return result;
};
